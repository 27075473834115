import React, {Fragment, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export interface ScrollToTopProps {
    children?: React.ReactNode;
}

export default function (props: ScrollToTopProps) {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <Fragment>{props.children}</Fragment>;
}