import React from 'react'
import './Header.css';
import luis from '../images/luis.png';
import tonka from '../images/tonka.png';
import kahleesi from '../images/kahleesi.png';

export default function () {
    return (
        <div className="header">
            <div className="header-title">PQK Kennels & Play Yards</div>
            <div className="header-subtitle">Home of the Peace & Quiet Killas.</div>
            <div className="header-luis">
                <img src={luis} alt="luis"/>
            </div>
            <div className="header-tonka">
                <img src={tonka} alt="luis"/>
            </div>
            <div className="header-kahleesi">
                <img src={kahleesi} alt="kahleesi"/>
            </div>
        </div>
    )
}