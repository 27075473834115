import React, {useEffect, useState} from 'react'
import './PlayYard3D.css';
import {HexColorString, Material, Mesh, MeshPhongMaterial, NoToneMapping} from "three";
import {OrbitControls, useFBX} from "@react-three/drei";
import {Canvas} from "@react-three/fiber";

export type PlayYardComponentType = 'fitting' | 'pipe' | 'foot';

export interface PlayYardComponent {
    id: string;
    type: PlayYardComponentType;
    label: string;
    colorGetter: () => PlayYardColor;
    colorSetter: React.Dispatch<React.SetStateAction<PlayYardColor>>;
}

export interface PlayYardColor {
    name: string;
    color: HexColorString;
    supportedTypes: PlayYardComponentType[];
}

export default function () {
    const white: PlayYardColor = {name: 'White', color: '#fff', supportedTypes: ['fitting', 'pipe', 'foot']};
    const black: PlayYardColor = {name: 'Black', color: '#000', supportedTypes: ['fitting', 'pipe', 'foot']};
    const gray: PlayYardColor = {name: 'Gray', color: '#808080', supportedTypes: ['fitting', 'pipe']};
    const red: PlayYardColor = {name: 'Red', color: '#eb0505', supportedTypes: ['fitting', 'pipe']};
    const orange: PlayYardColor = {name: 'Orange', color: '#ff6000', supportedTypes: ['fitting', 'pipe']};
    const yellow: PlayYardColor = {name: 'Yellow', color: '#ffd100', supportedTypes: ['fitting', 'pipe']};
    const green: PlayYardColor = {name: 'Green', color: '#01784b', supportedTypes: ['fitting', 'pipe']};
    const blue: PlayYardColor = {name: 'Blue', color: '#0153a4', supportedTypes: ['fitting', 'pipe']};
    const purple: PlayYardColor = {name: 'Purple', color: '#810180', supportedTypes: ['fitting', 'pipe']};
    const pink: PlayYardColor = {name: 'Pink', color: '#f368ae', supportedTypes: ['fitting', 'pipe']};

    const colors: PlayYardColor[] = [black, white, gray, red, orange, yellow, green, blue, purple, pink];

    const [fitting1Color, setFitting1Color] = useState<PlayYardColor>(blue);
    const [fitting2Color, setFitting2Color] = useState<PlayYardColor>(red);
    const [fitting3Color, setFitting3Color] = useState<PlayYardColor>(blue);
    const [fitting4Color, setFitting4Color] = useState<PlayYardColor>(red);

    const [top1Color, setTop1Color] = useState<PlayYardColor>(white);
    const [top2Color, setTop2Color] = useState<PlayYardColor>(white);
    const [top3Color, setTop3Color] = useState<PlayYardColor>(white);
    const [top4Color, setTop4Color] = useState<PlayYardColor>(white);

    const [leg1Color, setLeg1Color] = useState<PlayYardColor>(white);
    const [leg2Color, setLeg2Color] = useState<PlayYardColor>(white);
    const [leg3Color, setLeg3Color] = useState<PlayYardColor>(white);
    const [leg4Color, setLeg4Color] = useState<PlayYardColor>(white);

    const [foot1Color, setFoot1Color] = useState<PlayYardColor>(white);
    const [foot2Color, setFoot2Color] = useState<PlayYardColor>(white);
    const [foot3Color, setFoot3Color] = useState<PlayYardColor>(white);
    const [foot4Color, setFoot4Color] = useState<PlayYardColor>(white);

    const components: PlayYardComponent[] = [
        {id: 'fitting1', type: 'fitting', label: 'Fitting 1', colorGetter: () => fitting1Color, colorSetter: setFitting1Color},
        {id: 'fitting2', type: 'fitting', label: 'Fitting 2', colorGetter: () => fitting2Color, colorSetter: setFitting2Color},
        {id: 'fitting3', type: 'fitting', label: 'Fitting 3', colorGetter: () => fitting3Color, colorSetter: setFitting3Color},
        {id: 'fitting4', type: 'fitting', label: 'Fitting 4', colorGetter: () => fitting4Color, colorSetter: setFitting4Color},
        {id: 'pipe1', type: 'pipe', label: 'Top 1', colorGetter: () => top1Color, colorSetter: setTop1Color},
        {id: 'pipe2', type: 'pipe', label: 'Top 2', colorGetter: () => top2Color, colorSetter: setTop2Color},
        {id: 'pipe3', type: 'pipe', label: 'Top 3', colorGetter: () => top3Color, colorSetter: setTop3Color},
        {id: 'pipe4', type: 'pipe', label: 'Top 4', colorGetter: () => top4Color, colorSetter: setTop4Color},
        {id: 'pipe5', type: 'pipe', label: 'Leg 1', colorGetter: () => leg1Color, colorSetter: setLeg1Color},
        {id: 'pipe6', type: 'pipe', label: 'Leg 2', colorGetter: () => leg2Color, colorSetter: setLeg2Color},
        {id: 'pipe7', type: 'pipe', label: 'Leg 3', colorGetter: () => leg3Color, colorSetter: setLeg3Color},
        {id: 'pipe8', type: 'pipe', label: 'Leg 4', colorGetter: () => leg4Color, colorSetter: setLeg4Color},
        {id: 'foot1', type: 'foot', label: 'Foot 1', colorGetter: () => foot1Color, colorSetter: setFoot1Color},
        {id: 'foot2', type: 'foot', label: 'Foot 2', colorGetter: () => foot2Color, colorSetter: setFoot2Color},
        {id: 'foot3', type: 'foot', label: 'Foot 3', colorGetter: () => foot3Color, colorSetter: setFoot3Color},
        {id: 'foot4', type: 'foot', label: 'Foot 4', colorGetter: () => foot4Color, colorSetter: setFoot4Color}
    ];

    const [selectedComponent, setSelectedComponent] = useState<PlayYardComponent>(components[1]);

    useEffect(() => {
        // hack to stablize colors.
        setTimeout(() => {
            setSelectedComponent(components[0])
        }, 100);
    }, []);

    let model = useFBX("./assets/PlayYard.fbx");

    const createMaterial = (color: PlayYardColor): Material => {
        return new MeshPhongMaterial({
            "color": color.color,
            "emissive": 0,
            "specular": 3355443,
            "shininess": 20,
            "reflectivity": 1,
            "refractionRatio": 0.98,
            "depthFunc": 3,
            "depthTest": true,
            "depthWrite": true,
            "colorWrite": true,
            "stencilWrite": false,
            "stencilWriteMask": 255,
            "stencilFunc": 519,
            "stencilRef": 0,
            "stencilFuncMask": 255,
            "stencilFail": 7680,
            "stencilZFail": 7680,
            "stencilZPass": 7680
        });
    }

    let fittings: Mesh[] = [];
    let pipes: Mesh[] = [];
    let feet: Mesh[] = [];
    model.traverse((child: any) => {
        if (child instanceof Mesh) {
            if (child.name === 'Fitting') {
                fittings.push(child);
            } else if (child.name === 'Pipe') {
                pipes.push(child);
            } else if (child.name === 'Foot') {
                feet.push(child);
            }
        }
    });

    fittings[0].material = createMaterial(fitting1Color);
    fittings[1].material = createMaterial(fitting2Color);
    fittings[2].material = createMaterial(fitting3Color);
    fittings[3].material = createMaterial(fitting4Color);

    pipes[0].material = createMaterial(top1Color);
    pipes[1].material = createMaterial(top2Color);
    pipes[2].material = createMaterial(top3Color);
    pipes[3].material = createMaterial(top4Color);

    pipes[4].material = createMaterial(leg1Color);
    pipes[5].material = createMaterial(leg2Color);
    pipes[6].material = createMaterial(leg3Color);
    pipes[7].material = createMaterial(leg4Color);

    feet[0].material = createMaterial(foot1Color);
    feet[1].material = createMaterial(foot2Color);
    feet[2].material = createMaterial(foot3Color);
    feet[3].material = createMaterial(foot4Color);

    return (
        <div className="playyard-3d">
            <div className="playyard-3d-canvas-wrapper">
                <Canvas gl={{antialias: true, toneMapping: NoToneMapping}} linear style={{height: '80vw', maxHeight: '500px'}} className="playyard-3d-canvas" camera={{position: [250, 300, -500], zoom: 2}}>
                    <ambientLight intensity={1.5}/>
                    <primitive
                        object={model}
                        position={[0, 75, 0]}
                        scale={0.45}
                        rotation={[Math.PI / -2, 0, 0]}
                    />
                    <OrbitControls enablePan={false} enableZoom={false}/>
                </Canvas>
            </div>
            <div className="playyard-3d-component-selector">
                {components.map((component, i) => (
                    <div key={i}
                         className={'playyard-3d-component' + (component.id === selectedComponent.id ? ' playyard-3d-component-selected' : '')}
                         onClick={() => setSelectedComponent(component)}>
                        <div className="playyard-3d-component-label">{component.label}</div>
                        <div className="playyard-3d-component-color" style={{backgroundColor: component.colorGetter().color}}/>
                    </div>
                ))}
            </div>
            <div className="playyard-3d-color-selector">
                {colors.map((color, i) => (
                    <div key={i}
                         className={'playyard-3d-color' + (!color.supportedTypes.includes(selectedComponent.type) ? ' playyard-3d-color-unavailable' : '')}
                         style={{backgroundColor: color.color}}
                         onClick={() => {
                             if (!color.supportedTypes.includes(selectedComponent.type)) {
                                 return;
                             }
                             selectedComponent.colorSetter(color)
                         }}/>
                ))}
            </div>
        </div>
    )
}