import React from 'react'
import './Home.css';
import {Link} from "react-router-dom";

export interface Column {
    title: string;
    text: string;
    path: string;
}

export default function () {
    const Columns: Column[] = [
        {
            title: 'Play Yards',
            text: 'Need a solution to keep your furbabies occupied while you get things done around the house or while you are away from home? Check out our custom built Play Yards!',
            path: 'playyards'
        }, {
            title: 'Puppies',
            text: 'We are planning to have our first litter of puppies this winter. We will post updates once we finalize our stud wishlist and once we have a firmer timeline for the litter.  Be sure to follow us on social media for the latest updates.',
            path: 'puppies'
        }, {
            title: 'Kahleesi Willow',
            text: 'Kahleesi Willow is set to become the matriarch of our line. She is a beautiful Blue Merle Frenchie with tan points. We are very excited about her potential to produce a bunch of really unique puppies!',
            path: 'kahleesi'
        }, {
            title: 'King Luis',
            text: 'King Luis, a.k.a the original PQK himself, is the handsome fellow that inspired our company name. Luis is not part of our breeding plans, but PKQ Kennels would not be nearly as interesting without his boisterous personality. He truly epitomizes what it means to be a bulldog!',
            path: 'luis'
        }, {
            title: 'Tonka Truck',
            text: 'Tonka Truck, better known as just Tonka, is certainly no small part of the PQK family. He\'s a big boy, but he\'s still no match for the larger-than-life attitudes that come out of our Frenchies!',
            path: 'tonka'
        }, {
            title: 'Our Story',
            text: 'How did we get started? What are our breeding plans? What does PQK even stand for? What kind of breeders are we? The best way to find out the answers to these questions and more is to check out our story in the About Us section or follow us on social media.',
            path: 'about'
        }
    ]

    return (
        <div className="home">
            {Columns.map((column, index) => {
                return (
                    <div className="home-article" key={index}>
                        <div className="home-article-title">{column.title}</div>
                        <div className="home-article-content">
                            <div>{column.text}</div>
                            <div>
                                <Link className="home-article-more-link" to={{pathname: column.path}}>learn more</Link>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}