import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom';
import './Tab.css';

export interface TabProps {
    text: string;
    url: string;
}

export default function (props: TabProps) {
    const [selected, setSelected] = useState<boolean>();

    let location = useLocation();

    useEffect(() => {
        setSelected(location.pathname.slice(1) === props.url);
    }, [location.pathname])
    return (
        <div className={'tab ' + (selected ? 'tab-selected' : '')}>
            <Link to={{pathname: props.url}}>{props.text}</Link>
        </div>
    )
}