import React from 'react'
import './SocialBar.css';
import facebookIcon from '../images/facebook_icon.svg';
import instagramIcon from '../images/instagram_icon.svg';
import tiktokIcon from '../images/tiktok_icon.svg';
import emailIcon from '../images/email_icon.svg';
import etsyIcon from '../images/etsy_icon.svg';

export default function () {
    return (
        <div className="social-bar">
            <a href="https://www.facebook.com/profile.php?id=100090562063451" target="_blank"><img src={facebookIcon} alt="facebook icon"/></a>
            <a href="https://www.instagram.com/pqkkennels" target="_blank"><img src={instagramIcon} alt="instagram icon"/></a>
            <a href="https://www.tiktok.com/@pqkkennels" target="_blank"><img src={tiktokIcon} alt="tiktok icon"/></a>
            <a href="https://www.etsy.com/shop/PQKplayyards" target="_blank"><img src={etsyIcon} alt="etsy icon"/></a>
            <a href="mailto:pqkkennels@gmail.com"><img src={emailIcon} alt="email icon"/></a>
        </div>
    )
}