import React from 'react'
import './About.css';
import gotchaDay from '../images/gotcha_day.jpg';

export default function () {
    return (
        <div className="about">
            <div className="title">Our Story</div>
            <img className="about-picture" src={gotchaDay} alt="gotcha day picture"/>
        </div>
    )
}