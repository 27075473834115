import React from 'react';
import * as ReactDOMClient from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import './index.css'
import App from "./App";
import ScrollToTop from "./utils/ScrollToTop";

const root = ReactDOMClient.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop>
                <App/>
            </ScrollToTop>
        </Router>
    </React.StrictMode>
);