import React from 'react'
import './Kahleesi.css';
import kahleesi1 from '../images/kahleesi_large1.jpg';
import kahleesi2 from '../images/kahleesi_large2.jpg';

export default function () {
    return (
        <div className="kahleesi">
            <div className="title">Kahleesi Willow</div>
            <img className="kahleesi-picture" src={kahleesi1} alt="kahleesi picture 1"/>
            <img className="kahleesi-picture" src={kahleesi2} alt="kahleesi picture 2"/>
        </div>
    )
}