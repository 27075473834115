import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import './NavigationBar.css';
import Tab from "./Tab";

export default function () {
    let location = useLocation();
    let navigate = useNavigate();

    return (
        <div className="navigation-bar-wrapper">
            <div className="navigation-bar">
                <Tab text="Home" url=""/>
                <Tab text="Play Yards" url="playyards"/>
                <Tab text="Puppies" url="puppies"/>
                <Tab text="Kahleesi Willow" url="kahleesi"/>
                <Tab text="King Luis" url="luis"/>
                <Tab text="Tonka Truck" url="tonka"/>
                <Tab text="About Us" url="about"/>
            </div>
            <div className="navigation-bar-compact">
                <select className="navigation-bar-compact-selector" value={location.pathname.slice(1)} onChange={(e) => navigate(e.target.value)}>
                    <option value=''>Home</option>
                    <option value='playyards'>Play Yards</option>
                    <option value='puppies'>Puppies</option>
                    <option value='kahleesi'>Kahleesi Willow</option>
                    <option value='luis'>King Luis</option>
                    <option value='tonka'>Tonka Truck</option>
                    <option value='about'>About Us</option>
                </select>
            </div>
        </div>
    )
}