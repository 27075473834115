import React from 'react'
import './Puppies.css';
import kahleesi from '../images/kahleesi_large2.jpg';
import frenchie from '../images/frenchie.png';
import xIcon from '../images/x_icon.svg';

export default function () {
    return (
        <div className="puppies">
            <div className="title">Puppies - Winter 2023</div>
            <div className="puppies-pictures">
                <img className="puppies-picture" src={kahleesi} alt="kahleesi picture"/>
                <img className="puppies-picture-divider" src={xIcon} alt="x icon"/>
                <img className="puppies-picture" src={frenchie} alt="frenchie silhouette"/>
            </div>
            <div className="puppies-text">Check back this fall or follow us on social media to find out who we'll be breeding with Kahleesi for our first litter!</div>
        </div>
    )
}