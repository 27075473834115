import React from 'react'
import './PlayYards.css';
import PlayYard3D from "../components/PlayYard3D";

export interface Thumbnail {
    name: string;
}

export interface Gallery {
    title: string;
    key: string;
    count: number;
}

export default function () {
    const Galleries: Gallery[] = [
        {
            title: 'The Gator',
            key: 'gator',
            count: 3
        }, {
            title: 'The Bulldog',
            key: 'bulldog',
            count: 2
        }, {
            title: 'The Rainbow',
            key: 'rainbow',
            count: 4
        }, {
            title: 'The Catnip',
            key: 'catnip',
            count: 4
        }
    ];

    return (
        <div className="playyards">
            <div className="title">Play Yards</div>
            <div className="playyards-text">
                <p>Need a solution to keep your furbabies occupied while you get things done around the house or while you are away from home? If so, you've come to the right place!</p>
                <p>Our Play Yards began as a solution to our own problems with keeping a house full of needy pups busy. A person only has two hands, and a never ending game of tug-of-war doesn't equate to getting much else done.</p>
                <p>If your furbabies love to play, and you'd like a break, you NEED one of these! Send us a message on <a href="https://www.facebook.com/profile.php?id=100090562063451" target="_blank">Facebook</a> or head on over to our <a
                    href="https://www.etsy.com/shop/PQKplayyards" target="_blank">Etsy Shop</a> to order yours today!</p>
            </div>
            <div className="playyards-section-divider">. . . . .</div>
            <div className="playyards-section-title">Play Yard Preview</div>
            <div className="playyards-text">Our Play Yard Preview tool can be used to customize and preview the look of your very own Play Yard. Don't forget to browse the Play Yard Gallery below for inspiration!</div>
            <PlayYard3D/>
            <div className="playyards-section-divider">. . . . .</div>
            <div className="playyards-section-title">Play Yard Gallery</div>
            <div className="playyards-text">Get inspired! The Play Yard Gallery contains pictures of some of the Play Yards we've already built for others. Order yours today!</div>
            {
                Galleries.map((gallery, index) => {
                    return (
                        <div className="playyards-gallery" key={index}>
                            <div className="subtitle">{gallery.title}</div>
                            {Array.from(Array(gallery.count), (element, index) => {
                                return (
                                    <div className="playyards-thumbnail" key={index}>
                                        <img src={require('../images/playyards/' + gallery.key + (index + 1) + '.jpg')} alt="thumbnail"/>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })
            }
        </div>
    )
}