import React, {Suspense} from 'react'
import './App.css';
import Home from "./pages/Home";
import {Route, Routes} from "react-router-dom";
import About from "./pages/About";
import NavigationBar from "./components/NavigationBar";
import SocialBar from "./components/SocialBar";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Tonka from "./pages/Tonka";
import Luis from "./pages/Luis";
import Kahleesi from "./pages/Kahleesi";
import Puppies from "./pages/Puppies";
import PlayYards from "./pages/PlayYards";

export default function () {
    return (
        <div className="app">
            <Header/>
            <NavigationBar/>
            <div className="app-body">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/playyards" element={<Suspense fallback={null}><PlayYards/></Suspense>}/>
                    <Route path="/puppies" element={<Puppies/>}/>
                    <Route path="/kahleesi" element={<Kahleesi/>}/>
                    <Route path="/luis" element={<Luis/>}/>
                    <Route path="/tonka" element={<Tonka/>}/>
                    <Route path="/about" element={<About/>}/>
                </Routes>
            </div>
            <SocialBar/>
            <Footer/>
        </div>
    )
}