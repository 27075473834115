import React from 'react'
import './Tonka.css';
import tonka from '../images/tonka_large.jpg';

export default function () {
    return (
        <div className="tonka">
            <div className="title">Tonka Truck</div>
            <img className="tonka-picture" src={tonka} alt="tonka picture"/>
        </div>
    )
}