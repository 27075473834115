import React from 'react'
import './Luis.css';
import luis from '../images/luis_large.jpg';

export default function () {
    return (
        <div className="luis">
            <div className="title">King Luis</div>
            <img className="luis-picture" src={luis} alt="luis picture"/>
        </div>
    )
}